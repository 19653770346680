export const DEFAULT_LOCALE = process.env.DEFAULT_LOCALE || 'en'
export const NODE_ENV = process.env.NODE_ENV
export const APP_ENVIRONMENT =
  process.env.NEXT_PUBLIC_APP_ENVIRONMENT ?? 'development'
export const PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL
export const PUBLIC_APP_URL = process.env.NEXT_PUBLIC_APP_URL
export const PUBLIC_APP_NAME = process.env.NEXT_PUBLIC_APP_NAME

export const EMULATOR = process.env.NEXT_PUBLIC_EMULATOR || ''
export const GLEAP_API_KEY = process.env.NEXT_PUBLIC_GLEAP_API_KEY || ''

export const GOOGLE_RECAPTCHA_SECRET_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SECRET_API_KEY || ''
export const GOOGLE_RECAPTCHA_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_API_KEY || ''
