export const ERROR_500_URL = '/500'
export const ERROR_404_URL = '/404'
export const ERROR_ACCESS_DENIED_URL = '/access-denied'

export const DEFAULT_URL = '/'
export const PREVIEW_IMAGE = '/preview.png'

export const CONTACT_REQUEST_URL = '/request-demo'
export const TERM_OF_SERVICES_URL = '/term-of-services'
export const PRIVACY_POLICY_URL = '/privacy-policy'
export const ATTRACT_SOURCE_URL = 'https://hireforce.io/attract-and-source'
export const TRACK_COLLABORATE_URL =
  'https://hireforce.io/track-and-collaborate'
export const HIRE_ANALYTICS_URL = 'https://hireforce.io/hire-and-analyze'
export const FEATURES_URL = 'https://hireforce.io/all-features'
export const PRICING_URL = '/pricing'
export const CONTACT_US_URL = '/contact-us'
export const HR_OKR_EBOOK_URL = 'https://get.hireforce.io/hr-okr-ebook'
export const JOB_DESCRIPTIONS_URL = 'https://get.hireforce.io/job-descriptions'
export const INTERVIEW_QUESTION_URL =
  'https://get.hireforce.io/interview-question-template'

export const FACEBOOK_URL = 'https://www.facebook.com/hireforce.io'
export const LINKEDIN_URL = 'https://www.linkedin.com/company/hireforce-io'
export const X_URL = 'https://x.com/hireforce_io'
export const PRODUCT_HUNT_URL = 'https://www.producthunt.com/products/hireforce'

// Protected Static Pages
export const PAGES_PROTECTED_STATIC: { [key: string]: Array<string> } = {}

// Protected Dynamic Pages
export const URL_TERM_OF_SERVICE = `https://freec-cms.directus.app/items/hireforce_term_and_service`
export const URL_PRIVACY_POLICY = `https://freec-cms.directus.app/items/hireforce_privacy_policy`
export const URL_HELP_CENTER = `https://help.hireforce.io`
export const URL_LOGIN_APP = `https://app.hireforce.io/login`
export const URL_REGISTER_APP = `https://app.hireforce.io/register`
export const URL_BLOG = `https://blog.hireforce.io/`
